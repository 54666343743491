export default [
  { value: null, text: "" },
  { value: "anesthetic", text: "Anesthetic" },
  { value: "cannabinoid", text: "Cannabinoid" },
  { value: "ginarcotic", text: "GI Narcotic" },
  { value: "narcotic", text: "Narcotic" },
  { value: "neuropain", text: "Neuropain" },
  { value: "other", text: "Other" },
  { value: "sedative", text: "Sedative" },
  { value: "steroid", text: "Steroid" },
  { value: "stimulant", text: "Stimulant" },
  { value: "unassigned", text: "Unassigned" }
];
