<doc>
This is a container element compliant with the light and heavy content container on the style guide: https://25pibt.axshare.com/#id=uww3vw&p=page_1&g=1

The "content" property can be applied depending on how heavy the content is. Options are "light", "medium" and "heavy". Default is "light" which
has the least horizontal padding.
</doc>

<template>
  <div class="appriss-container" :style="padding">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    content: {
      type: String,
      default: "light"
    }
  },
  computed: {
    size() {
      switch (this.content) {
        case "light":
          return "2.5rem";
        case "medium":
          return "1.5rem";
        case "heavy":
          return "1rem";
        default:
          return "1rem";
      }
    },
    padding() {
      return {
        "padding-left": this.size,
        "padding-right": this.size
      };
    }
  }
};
</script>

<style>
.appriss-container {
  padding-top: 1.5rem;
  /* padding-left: 2.5rem;
  padding-right: 2.5rem; */
}
</style>
